




































import Vue from 'vue'

import { SharedConstants } from '@knitiv/api-client-javascript'
import ClassViewerList from '@/components/importfile/class-viewer-list.vue'
import Page from '@/components/layouts/page.vue'

export default Vue.extend({
  name: 'ClassDesigner',
  components: {
    ClassViewerList,
    Page,
  },
  data() {
    return {
      classId: SharedConstants.nodes.CLASS_DESIGNER_SCHEMA,
    }
  },
})
